import { Field } from "formik";
import DataTable from "../../../components/DataTable/DataTable";
import { JourneyEvent } from "../../../store/types/macroState";
import TagsInput from "../../../components/TagsInput/TagsInput";

interface ChangeJourneyEventsDataTableProps {
  data?: any[];
  loading: boolean;
  integration?: string;
}

const ChangeJourneyEventsDataTable: React.FC<
  ChangeJourneyEventsDataTableProps
> = ({ data, loading, integration }) => {

  const journeyEventsColumns = [
    { key: "name", label: "Nome" },
    {
      key: `integrationCodes[${integration}]`,
      label: "Códigos de integração",
      render: (item: JourneyEvent, index?: number) => (
        <div className="flex justify-center items-center">
          <Field name={`integrationCodes[${integration}][${item.type}]`} component={TagsInput} />
        </div>
      ),
    },
  ];

  return (
    <DataTable
      data={data ?? []}
      columns={journeyEventsColumns}
      onDetails={null}
      onEdit={null}
      loading={loading}
    />
  );
};

export default ChangeJourneyEventsDataTable;
