import {
  MoneyIcon,
  PlusIcon,
  RouteIcon,
  SettingIcon,
} from "../../../assets/imgs";
import Modal from "../../../components/Modal/Modal";
import { Form, Field, Formik } from "formik";
import FilledButton from "../../../components/FilledButton/FilledButton";
import * as Yup from "yup";
import { useStore } from "../../../store/useStore";
import OutlinedInput from "../../../components/FormComponents/OutlinedInput/OutlinedInput";
import Tabs from "../../../components/Tabs/Tabs";
import { useEffect, useRef, useState } from "react";
import Tab from "../../../components/Tabs/Components/Tab";
import TabPanel from "../../../components/Tabs/Components/TabPanel";
import {
  Parameter,
  REGIME_TRANSLATION,
} from "../../../store/types/journeyRule";
import ChangeParametersDataTable from "./ChangeParametersDataTable";
import ChangeJourneyEventsDataTable from "./ChangeJourneyEventsDataTable";
import { JourneyEvent } from "../../../store/types/macroState";
import ChangePayRollsDataTable from "./ChangePayRollsDataTable";
import OutlinedSelect from "../../../components/FormComponents/OutlinedSelect/OutlinedSelect";
import journeyRuleService from "../../../services/journeyRuleService";
import macroService from "../../../services/macroService";
import { toast } from "react-toastify";
import { AVAILABLE_INTEGRATIONS } from "../../../utils/constants/constants";

interface EditJourneyRuleProps {
  onClose: () => void;
}

const EditJourneyRuleModal: React.FC<EditJourneyRuleProps> = ({ onClose }) => {
  const [tab, setTab] = useState<number>(0);
  const [payRolls, setPayRolls] = useState<any[]>([]);
  const [isLoadingDataTablesContent, setIsLoadingDataTablesContent] =
    useState<boolean>(false);

  const selectedJourneyRule = useStore(
    (state) => state.journeyRules.selectedJourneyRule
  );
  const updateJourneyRule = useStore(
    (state) => state.journeyRules.updateJourneyRule
  );
  const isUpdatingJourneyRule = useStore(
    (state) => state.journeyRules.isUpdatingJourneyRule
  );

  const tableRef = useRef<any>(null);

  return (
    <Modal
      title="Editar regra de jornada"
      icon={<PlusIcon width={30} height={30} />}
      onClose={onClose}
      className="w-[90%] h-[100%]"
      childrenClassName="px-5 overflow-y-auto"
    >
      <Formik
        initialValues={{
          name: "",
          description: "",
          regime: "",
          parameters: [],
          integration: AVAILABLE_INTEGRATIONS[0],
          integrationCodes: AVAILABLE_INTEGRATIONS.reduce((acc, value) => {
              acc[value] = {};
              return acc;
            }, {} as Record<string, any>)
          ,
          journeyEvents: [],
          payRolls: [],
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required("Campo obrigatório"),
          description: Yup.string().required("Campo obrigatório"),
          regime: Yup.string().required("Campo obrigatório"),
          integration: Yup.string().required("Campo obrigatório"),
          parameters: Yup.array().of(
            Yup.object().shape({
              activation: Yup.boolean(),
              type: Yup.string(),
              measure: Yup.string(),
              value: Yup.lazy((value, { parent }) => {
                const { measure } = parent;

                switch (measure) {
                  // case "MULTI_SELECT":
                  //   return Yup.object()
                  //     .required("Pelo menos um dia deve estar selecionado")
                  //     .test(
                  //       "at-least-one-selected",
                  //       "Pelo menos uma opção deve estar selecionada",
                  //       (days) => {
                  //         return Object.values(days).some(
                  //           (day: any) => day.value === true
                  //         );
                  //       }
                  //     );
                  case "CHECK":
                    return Yup.object({
                      value: Yup.boolean().required("Required field"),
                    });
                  case "SELECT":
                    return Yup.object()
                      .required("Required field")
                      .test(
                        "at-least-one-selected",
                        "Pelo menos uma opção deve estar selecionada",
                        (options) => {
                          return Object.values(options).some(
                            (option: any) => option.value === true
                          );
                        }
                      );
                  case "TIME_BY_WEEK_DAY":
                    return Yup.object({
                      MONDAY: Yup.object({
                        name: Yup.string().required("Required field"),
                        value: Yup.string().required("Required field"),
                      }),
                      TUESDAY: Yup.object({
                        name: Yup.string().required("Required field"),
                        value: Yup.string().required("Required field"),
                      }),
                      WEDNESDAY: Yup.object({
                        name: Yup.string().required("Required field"),
                        value: Yup.string().required("Required field"),
                      }),
                      THURSDAY: Yup.object({
                        name: Yup.string().required("Required field"),
                        value: Yup.string().required("Required field"),
                      }),
                      FRIDAY: Yup.object({
                        name: Yup.string().required("Required field"),
                        value: Yup.string().required("Required field"),
                      }),
                      SATURDAY: Yup.object({
                        name: Yup.string().required("Required field"),
                        value: Yup.string().required("Required field"),
                      }),
                      SUNDAY: Yup.object({
                        name: Yup.string().required("Required field"),
                        value: Yup.string().required("Required field"),
                      }),
                    }).required("Required field");
                  case "TIME":
                    return Yup.object({
                      value: Yup.string().required("Required field"),
                    });
                  case "DAY_TIME_INTERVAL":
                    return Yup.object({
                      value: Yup.array().of(
                        Yup.string().required("Required field")
                      ),
                    });
                  case "PERCENT":
                    return Yup.object({
                      value: Yup.number()
                        .required("Required field")
                        .min(0, "A porcentagem deve ser no mínimo 0")
                        .max(100, "A porcentagem deve ser no máximo 100")
                        .typeError("A porcentagem deve ser um número"),
                    });
                  default:
                    return Yup.mixed().notRequired();
                }
              }),
            })
          ),
        })}
        onSubmit={(values) => {
          if (!selectedJourneyRule?._id) return;

          const parameters = values.parameters.reduce((acc: any, p: any) => {
            acc[p.type] = p.activation ? p.value : null;
            return acc;
          }, {});

          const integration_codes: any[] = [];
          AVAILABLE_INTEGRATIONS.forEach((integration) => {
            integration_codes.push({
              company: integration,
              journey_events: values.integrationCodes[integration]
            });
          });

          const payroll_events = values.payRolls.reduce((acc: any, p: any) => {
            acc[p.journeyEventType] = p.value;
            return acc;
          }, {});

          const data = {
            company: selectedJourneyRule.company,
            name: values.name,
            description: values.description,
            regime: values.regime,
            parameters,
            integration_codes,
            payroll_events,
          };

          updateJourneyRule(selectedJourneyRule?._id, data);
        }}
      >
        {(formik) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            setIsLoadingDataTablesContent(true);

            if (!selectedJourneyRule) return;

            const filters = { sort_key: "name", sort_value: "asc" };
            const params = {
              filters,
              limit: 9999,
              offset: 0,
            };

            const getParameters = journeyRuleService.getParameters(params);
            const getJourneyEvents = macroService.getJourneyEvents(params);
            const getPayRolls = journeyRuleService.getPayRolls(params);

            Promise.all([getParameters, getJourneyEvents, getPayRolls])
              .then((values) => {
                const parameters = values[0]?.data ?? [];
                const journeyEvents = values[1]?.data ?? [];

                setPayRolls(values[2]?.data ?? []);

                const integration = Object.keys(
                  selectedJourneyRule.journey_events_code
                )[0];
                formik.setFieldValue("integration", integration);
                formik.setFieldValue("regime", selectedJourneyRule?.regime);
                formik.setFieldValue("name", selectedJourneyRule?.name);
                formik.setFieldValue(
                  "description",
                  selectedJourneyRule?.description
                );

                formik.setFieldValue(
                  "parameters",
                  parameters.map((p: Parameter) => {
                    return {
                      ...p,
                      activation:
                        !!selectedJourneyRule.parameters[p.type].custom_value,
                      type: p.type,
                      measure: p.measure,
                      value: selectedJourneyRule.parameters[p.type].custom_value
                        ? selectedJourneyRule.parameters[p.type].custom_value
                        : p.default,
                    };
                  })
                );

                formik.setFieldValue(
                  "journeyEvents",
                  journeyEvents.map((j: JourneyEvent) => {
                    return {
                      ...j,
                      journeyEventType: j.type,
                      codes:
                        selectedJourneyRule.journey_events_code[integration][
                          j.type
                        ],
                    };
                  })
                );

                let integrationCodes: any = {};
                AVAILABLE_INTEGRATIONS.forEach((integration) => {
                  integrationCodes[integration] = {
                    ...selectedJourneyRule.journey_events_code[integration] ?? [],
                  };
                });

                formik.setFieldValue(
                  "integrationCodes",
                  integrationCodes
                );

                formik.setFieldValue(
                  "payRolls",
                  journeyEvents.map((j: JourneyEvent, index: number) => {
                    return {
                      journeyEvent: j.name,
                      journeyEventType: j.type,
                      value: selectedJourneyRule.payroll_events[j.type]
                        ? selectedJourneyRule.payroll_events[j.type]
                        : j.default_payroll_event.type,
                    };
                  })
                );

                setIsLoadingDataTablesContent(false);
              })
              .catch((error) => {
                toast.error(error.message);
              })
              .finally(() => {
                setIsLoadingDataTablesContent(false);
              });
            // eslint-disable-next-line react-hooks/exhaustive-deps
          }, [selectedJourneyRule]);

          return (
            <Form>
              <div className="flex flex-col gap-8 pt-3">
                <div className="flex gap-10">
                  <div className="min-w-[212px]">
                    <Field
                      placeholder="Nome"
                      name="name"
                      loading={isLoadingDataTablesContent}
                      component={OutlinedInput}
                    />
                  </div>
                  <div className="min-w-[212px]">
                    <Field
                      placeholder="Descrição"
                      name="description"
                      loading={isLoadingDataTablesContent}
                      component={OutlinedInput}
                    />
                  </div>
                  <div className="min-w-[212px]">
                    <Field
                      placeholder="Regime"
                      name="regime"
                      loading={isLoadingDataTablesContent}
                      component={OutlinedSelect}
                      options={[
                        {
                          label: REGIME_TRANSLATION["MONTHLY"],
                          value: "MONTHLY",
                        }
                      ]}
                    />
                  </div>
                </div>
                <div>
                  <div className="flex items-start justify-between mb-5">
                    <Tabs tab={tab} setTab={setTab}>
                      <Tab label="Parâmetros" icon={<SettingIcon />} />
                      <Tab label="Eventos de Jornada" icon={<RouteIcon />} />
                      <Tab label="Eventos de Folha" icon={<MoneyIcon />} />
                    </Tabs>
                    {tab === 1 ? (
                      <div className="shadow-base rounded-[12px] p-5 mr-10 mt-[-55px]">
                        <div className="text-dark-blue font-bold text-lg pb-2">
                          Selecione a Integração
                        </div>
                        <Field
                          name="integration"
                          component={OutlinedSelect}
                          allowEmptyOption={false}
                          options={[
                            { label: "ONIXSAT", value: "ONIXSAT" },
                            { label: "SASCAR", value: "SASCAR" },
                            { label: "OMNILINK", value: "OMNILINK" }
                          ]}
                        />
                      </div>
                    ) : null}
                  </div>
                  <div id="table" ref={tableRef}>
                    <TabPanel tab={tab} index={0}>
                      <ChangeParametersDataTable
                        data={formik.values.parameters as any[]}
                        loading={isLoadingDataTablesContent}
                      />
                    </TabPanel>
                    <TabPanel tab={tab} index={1}>
                      <ChangeJourneyEventsDataTable
                        data={formik.values.journeyEvents as any[]}
                        loading={isLoadingDataTablesContent}
                        integration={formik.values.integration}
                      />
                    </TabPanel>
                    <TabPanel tab={tab} index={2}>
                      <ChangePayRollsDataTable
                        data={formik.values.journeyEvents as any[]}
                        loading={isLoadingDataTablesContent}
                        payRolls={payRolls}
                      />
                    </TabPanel>
                  </div>
                </div>
              </div>
              <div className="sticky z-30 bottom-0 py-4 bg-white w-full">
                <div className="flex justify-end">
                  <FilledButton
                    loading={isUpdatingJourneyRule}
                    className="px-20"
                    type="submit"
                  >
                    Editar
                  </FilledButton>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default EditJourneyRuleModal;
